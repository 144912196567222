@mixin clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
@mixin borderbox() {
  *, *:before, *:after {
    box-sizing: border-box;
  }
}


$green: #8EC558;
$red: #DA373B;

.info-box {
  background: #ffffff !important;
  //color: $gray-text-dark;
  padding: 10px;
  //font-size: 14px;
  margin: 20px 0;
  position: relative;
  border: 1px solid #E1E1E1;
  @include clearfix;
  h4 {
    margin: 0 0 10px 0;
    text-transform: uppercase;
  }
  p {
    margin: 0;
  }
  &__image {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  &__body {
    padding: 0 0 0 45px;
    //font-weight: 700;
    //font-size: 16px;
    ul {
      margin: 0;
      padding: 0;
    }
  }
  &__message {
    float: left;
    padding: 0;
  }
  &.success {
    border-color: $green;
    h4 {
      color: $green;
    }
  }
  //&.warning {
  //  border-color: $orange;
  //  .info-box__image {
  //    svg path {
  //      fill: $orange;
  //    }
  //  }
  //}
  &.error {
    border-color: $red;
    h4 {
      color: $red;
    }
  }
}


div.column_full {
  padding-left: 27px;
  width: 717px;
}
div.column_int.sidebar-left {
  background: url(../img/bkg_int_sidebar_left.gif) repeat-y 0 0;
}

.panel {
  @include borderbox();
  border: 2px solid #f5f8ed;
  background: white;
  margin: 30px 0;
  button, .button {
    background-color: #6ba432;
    color: #FFF;
    border: none;
    font-size: 1em;
    //font-weight: 700;
    padding: 0 10px;
    min-width: 80px;
    margin-right: 18px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 0px;
    transition: all 0.23s ease-in-out 0s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: uppercase;
  }
  .button {
    text-decoration: none;
    font-style: normal;
    &--close {
      background: none;
      height: 20px;
      width: 20px;
      min-width: 20px;
      padding: 0;
      background: url(../img/btn-close.png) no-repeat;
      color: white;
      text-indent: -99999px;
    }
    &--light {
      background: #fff;
      border: 1px solid #6ba432;
      line-height: 30px;
      color: #6ba432;
    }
  }
  &__header {
    background: white;
    border-bottom: 1px solid #f5f8ed;
    padding: 10px;
    &__actions {
      float: right;
      .button {
        margin-right: 0px;
        margin-left: 18px;
      }
    }
    &__title {
      float: left;
      color: #6ba432;
      margin-top: 0.7em;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  &__content {
    background: white;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      text-align: left;
      margin-bottom: 24px;
      /*border: 1px solid #d4d4d4;*/
      background: white;
      border: 0px;
      margin: 0px;
      th {
        padding: 0.5em 1em;
        background: #f5f8ed;
        color: #6ba432;
        border-bottom: 1px solid #f5f8ed;
        text-align: left;
        /*line-height: 1.14286em;*/
      }
      td {
        padding: 10px;
        /*line-height: 1.14286em;*/
        .button {
          margin: 0 0 0 10px;
          &:first-child {
            margin-left: 0;
          }
        }
        &.table-row-actions {
          min-width: 190px;
        }
      }
      tr {
        td {
          border-bottom: 1px solid #e1e1e1;
        }
      }
      &:last-child {
        margin: 0 0 0 0;
        tbody {
          tr {
            &:last-child {
              td {
                border-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  .users-table__actions {
    width: 172px;
    white-space: nowrap;
  }
}


.form {
  $field-max-width: 232px;

  @include borderbox();

  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
    border: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .form-row{
    @include clearfix;
    &--last {
      .form-element {
        margin-bottom: 0;
      }
    }
  }

  .form-element {
    margin-bottom: 30px;
    max-width: $field-max-width;
    position: relative;
    &__help,
    &__tip {
      display: block;
      font-size: 11px;
      margin: 0.5em 0;
      line-height: 1em;
    }
    &__help {
      color: #DA373B;
      display: none;
    }
    &__tip {
      margin: 0.5em 0;
      transition: color 0.2s ease-out;
      color: #8B8A8A;
    }
    &--nolabel {
      padding-top: 28px;
      .button {
        width: 100%;
        height: 30px;
        margin: 0;
      }
    }
    &--last {
      margin-bottom: 0;
    }
    &--inline {
      margin-bottom: 0;
    }
    &--actions {
      max-width: 100%;
      text-align: center;
      background: white;
      padding: 10px 20px;
      button {
        margin: 0 9px;
      }
      .button {
        margin: 0 9px;
      }
      &__description {
        margin-bottom: 0.8em;
        font-weight: bold;
        font-size: 14px;
        color: #838484;
      }
    }
    &--full-width {
      max-width: 100%;
    }
    &--radio-inline {
      // width: auto;
      &__container {
        @include clearfix;
        padding: 7px 0 0 0;
        .radio-input {
          // max-width: 100px;
          float: left;
          margin-left: 20px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    &--dates {
      .form-element {
        margin-bottom: 0;
      }
    }
    &--has-error,
    &.has-error {
      .form-element__help{
        display: block;
      }
      textarea,
      input[type="text"],
      input[type="password"] {
        border-color: $red;
        border-width: 2px;
        min-height: 30px;
      }
      input[type="radio"] {
        & + span:before {
          box-shadow: 0 0 0 1px $red;
        }
      }
    }
  }

  button, .button {
    background-color: #6ba432;
    color: #FFF;
    border: none;
    font-size: 1em;
    //font-weight: 700;
    padding: 0 10px;
    min-width: 80px;
    margin-right: 18px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 0px;
    transition: all 0.23s ease-in-out 0s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: uppercase;
  }
  .button {
    text-decoration: none;
    font-style: normal;
    &--close {
      background: none;
      height: 20px;
      width: 20px;
      min-width: 20px;
      padding: 0;
      background: url(../img/btn-close.png) no-repeat;
      color: white;
      text-indent: -99999px;
    }
    &--light {
      background: #fff;
      border: 1px solid #6ba432;
      line-height: 30px;
      color: #6ba432;
    }
  }

  textarea,
  input[type="text"],
  input[type="password"] {
    display: inline-block;
    padding: 0 (8/16)+em;
    vertical-align: middle;
    width: 100%;
    border: 1px solid #E1E1E1;
    min-height: 30px;
    transition: border 0.1s linear, background-color 0.1s linear;
    //color: #E1E1E1;
    //&:focus {
    //  border: 1px solid lighten($blue, 10%);
    //  outline: none;
    //}
    @include placeholder {
      color: #8B8A8A;
    }
    &.inline-field {
      width: auto;
    }
  }

  label,
  select,
  textarea {
    font-size: 15px;
    line-height: 18px;
  }
  label {
    display: block;
    margin-bottom: 10px;
  }
  input[type="text"],
  input[type="password"] {
    height: 30px;
    border-radius: 0;
    &:disabled {
      background: #e8e8e8;
      color: #adaaaa;
      &.datepicker {
        & + span {
          svg {
            path {
              fill: #adaaaa;
            }
          }
        }
      }
    }
    &:read-only {
      background: #e8e8e8;
      color: #adaaaa;
    }
    &[readonly] {
      background: #e8e8e8;
      color: #adaaaa;
    }
  }
  .password-input {
    position: relative;
    // max-width: $field-max-width;
    input {
      padding-right: 80px;
    }
    //&__switch {
    //  display: block;
    //  font-size: 0.875em;
    //  font-weight: 300;
    //  text-align: right;
    //  position: absolute;
    //  right: 5px;
    //  top: 0;
    //  height: 100%;
    //  line-height: 1;
    //  cursor: pointer;
    //  @include user-select(none);
    //  color: #adaaaa;
    //  @include transition(color 0.2s ease-out);
    //  &:hover {
    //    color: darken(#adaaaa, 20%);
    //    path {
    //      fill: darken(#adaaaa, 20%);
    //    }
    //  }
    //  path {
    //    @include transition(fill 0.2s ease-out);
    //    fill: #adaaaa;
    //  }
    //  span {
    //    display: inline-block;
    //    vertical-align: middle;
    //  }
    //  span[class^="icon-"] {
    //    @include size(3em 3em);
    //  }
    //}
  }

  .radio-field,
  .checkbox-field {
    display: inline-block;
    input {
      & + span {
        cursor: pointer;
      }
      &[disabled] + span {
        cursor: default;
      }
    }
  }
  input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    & + span {
      display: inline-block;
      vertical-align: middle;
      font-weight: 400;
    }
    & + span:before {
      content: ' ';
      display: inline-block;
      width: 18px;
      height: 18px;
      vertical-align: middle;
      border-radius: 1em;
      border: 5px solid #fff;
      box-shadow: 0 0 0 1px #E1E1E1;
      margin-right: 0.75em;
      background-color: #fff;
    }
    &:checked + span:before {
      background: $green;
      box-shadow: 0 0 0 1px #adaaaa;
    }
    &:disabled {
      & + span {
        color: rgb(197, 197, 197);
      }
      & + span:before {
        box-shadow: 0 0 0 1px #adaaaa;
      }
      &:checked + span:before {
        background: #adaaaa;
      }
    }
    &:focus + span:before {
      box-shadow: 0 0 0 1px $green;
    }

  }

  [placeholder]:focus {
    @include placeholder {
      transition: opacity 0.23s 0.23s ease;
      opacity: 0;
    }
  }


  input[type=password]::-ms-reveal,
  input[type=password]::-ms-clear
  {
    display: none;
  }
}


.user-create-form {
  &__content {
    background: white;
    .form-element {
      width: 232px;
      float: left;
      display: block;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.overlay {
  display: none;
  &__mask {
    position: fixed;
    z-index: 1111;
    background: rgba(0, 0, 0, 0.4);
    background: url(../img/overlay-bkg.png) repeat;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &__wrapper {
    overflow: auto;
    z-index: 1111;
    right: 0;
    left: 0;
    position: absolute;
    top: 50%;
    top: 20%\9;
    transform: translateY(-50%);
    padding: 0;
    bottom: auto;
  }
  &__content {
    background: #fff;
    color: #444;
    position: relative;
    margin: 0 auto;
    max-width: 100%;
    width: 450px;
    font-size: 1.1em;
    line-height: 1.5em;
    padding: 0;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    &:before {
      position: absolute;
      font-size: 26px;
      font-weight: normal;
      line-height: 31px;
      text-align: center;
      color: #bbb;
      background: transparent;
      content: ' ';
      background: url(../img/close-icon.png) no-repeat 0 0;
      height: 20px;
      width: 20px;
      top: 10px;
      right: 7px;
    }
    &:hover {
      &:before {
        background: url(../img/close-icon.png) no-repeat 0 0;
      }
    }
  }
  &__inner {

  }
  &__header {
    border-top: 4px solid #bcbabb;
    background: white;
    color: #bcbabb;
    padding: 1em 2em;
    display: flex;
    align-items: center;
    h4 {
      text-transform: uppercase;
    }
    img {
      margin-right: 10px;
    }
  }
  &__text {
    background: #f8f9fa;
    padding: 2em;
  }
  &__actions {
    text-align: center;
    background: #f8f9fa;
    padding: 2em;
    button, .button {
      margin: 0 18px;
    }
  }
  &--error {
    .overlay__header {
      border-top: 4px solid $red;
      color: $red;
    }
  }
  button, .button {
    background-color: #6ba432;
    color: #FFF;
    border: none;
    font-size: 1em;
    //font-weight: 700;
    padding: 0 10px;
    min-width: 80px;
    margin-right: 18px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 0px;
    transition: all 0.23s ease-in-out 0s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: uppercase;
  }
  .button {
    text-decoration: none;
    font-style: normal;
    &--close {
      background: none;
      height: 20px;
      width: 20px;
      min-width: 20px;
      padding: 0;
      background: url(../img/btn-close.png) no-repeat;
      color: white;
      text-indent: -99999px;
    }
    &--light {
      background: #fff;
      border: 1px solid #6ba432;
      line-height: 30px;
      color: #6ba432;
    }
  }
}

.lt-ie9 {
  .form input[type="radio"] {
    clip: auto;
    margin-top: 0px;
    height: 20px;
    width: 20px;
  }
  .form input[type="radio"] span:before {
    display: none;
  }
}
